import { Flex } from 'components/box';
import { Text, Heading } from 'components/typography';
import { AlertTriangle } from 'react-feather';
import { css } from '@emotion/react';
import { Box } from 'components/box';

const SearchErrorFallback = () => (
  <Box
    height={['15vh', '30vh', '70vh']}
    width="100%"
    alignItems="center"
    backgroundColor="white"
    position="absolute"
  >
    <Flex
      mt={2}
      flexDirection="column"
      alignItems="center"
      textAlign="center"
      paddingX="5px"
      css={css`
        gap: 5px;
      `}
    >
      <AlertTriangle size={30} color="#FFC107" />
      <Heading fontSize={['0.9rem', 'm']}>Oops! Something went wrong.</Heading>

      <Text lineHeight={1.5}>
        There was a problem with the search functionality. Please refresh the
        page and try again.
      </Text>
    </Flex>
  </Box>
);

export default SearchErrorFallback;

import primary, {
  colors as baseColors,
  colorPalette as baseColorPalette,
  gradientConfettiPalette as baseGradientConfettiPalette,
} from './onedayonly';

const colorPalette = {
  ...baseColorPalette,
  lightPink: '#ff4776',
  middlePink: '#de094d',
  darkPink: '#c3044f',
};

const colors = {
  ...baseColors,
  headerPrimary: colorPalette.pink,
  headerSecondary: colorPalette.blue,
  primary: colorPalette.blue,
  secondary: colorPalette.darkLime,
};

const gradientConfettiPalette = {
  ...baseGradientConfettiPalette,
  default: {
    start: colorPalette.lightPink,
    middle: colorPalette.middlePink,
    end: colorPalette.darkPink,
    confetti: colorPalette.lightPink,
  },
};

const theme = {
  ...primary,
  name: 'anotherdayonly',
  colors,
  colorPalette,
  gradientConfettiPalette,
};

Object.freeze(theme);

export default theme;

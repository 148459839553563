import { AlgoliaIndexEnum } from './interface';
import { DependencyList, EffectCallback, ReactNode } from 'react';
import { FeatureDefinition } from '@growthbook/growthbook-react';
import { Mixpanel } from 'mixpanel-browser';

export type NoOp = {
  (value?: any): void | null;
};

export interface ToastPayload {
  body: ReactNode | string;
  timeout?: number;
  variant?: 'info' | 'warning' | 'error' | 'success';
}

export enum VideoProviderEnum {
  VIMEO = 'vimeo',
  YOUTUBE = 'youtube',
}
export enum PillVariantsEnum {
  highlighted = 'highlighted',
  information = 'information',
  warning = 'warning',
  yellow = 'yellow',
  grey = 'grey',
  muted = 'muted',
  info = 'info',
  red = 'red',
}

/**
 * Shamelessly stolen from stackoverflow:
 * https://stackoverflow.com/a/51365037
 *
 * Also see issue on TypeScript repo for more:
 * https://github.com/Microsoft/TypeScript/pull/12114#issuecomment-259776847
 *
 * NB: if you don't need/want recursion simply use Partial<T> which is a language default.
 */
export type RecursivePartial<T> = {
  [P in keyof T]?: T[P] extends (infer U)[]
    ? RecursivePartial<U>[] // eslint-disable-next-line @typescript-eslint/ban-types
    : T[P] extends object
    ? RecursivePartial<T[P]>
    : T[P];
};

/**
 * Neat trick to force an array to have at least one entry.
 * Thanks to @see https://bobbyhadz.com/blog/typescript-non-empty-array
 */
export type NonEmptyArray<T> = [T, ...T[]];

export interface MetaTag {
  keywords?: string;
  description?: string;
  title?: string;
  canonical?: string;
  noIndex?: boolean;
}

export interface PageLayoutProps {
  hideHeader?: boolean;
  hideFooter?: boolean;
  hideClock?: boolean;
  showAdoHeader?: boolean;
  searchIndexName?: AlgoliaIndexEnum;
  headerTaglineTitle?: string;
  headerTaglineText?: string;
  isNavigationBarActive?: boolean;
  metaTags?: MetaTag | null;
  selectedTopTab?: string | null;
  permanentShop?: PermanentShopType;
  gtmPageTitle?: string;
  gtagIsInitialized?: boolean;
  categories?: Category[];
  hasScrollButton?: boolean;
  hasAppDownloadWidget?: boolean;
  disableHeaderShrinking?: boolean;
  moreProductsFooter?: {
    enabled: boolean;
    message?: string;
  };
  disableConvertScript?: boolean;
}

export interface PageMetaProps {
  theme?: string;
  messages?: StickyMessageInterface[] | null;
  isAnotherDayOnlyActive?: boolean;
}

export type PageProps = {
  meta?: PageMetaProps;
  layout?: PageLayoutProps;
  [key: string]: any;
};

export interface AddressInput {
  firstName: string;
  lastName: string;
  phone: string;
  addressLine1: string;
  addressLine2?: string;
  organisation?: string;
  label?: string;
  suburb?: string;
  suburbId?: string;
  postalCode?: string;
  city?: string;
  provinceId?: string;
  provinceCode?: string;
  province?: string;
  countryId?: string;
  country?: string;
  saveInAddressBook?: boolean;
  pargoPupNumber?: string;
  isDefaultBilling?: boolean;
  isDefaultShipping?: boolean;
  specialInstructions?: string;
}

export enum GraphQLErrorCategoryEnum {
  Default = 'graphql',
  AlreadyExists = 'graphql-already-exists',
  Authentication = 'graphql-authentication',
  Authorization = 'graphql-authorization',
  AuthorizationExpired = 'graphql-authorization-expired',
  Input = 'graphql-input',
  InsufficientCredit = 'graphql-insufficient-credit',
  NoSuchEntity = 'graphql-no-such-entity',
  ReCaptcha = 'graphql-recaptcha-authentication',
}

export enum AuthenticationContext {
  LogIn = 'log-in',
  SignUp = 'sign-up',
}

export interface SellWithUsProduct {
  name: string;
  availableStock: string;
  costPrice: string;
  retailPrice: string;
  isSampleAvailable: string;
  link?: string;
  videoLink?: string;
}

export interface SellWithUsInput {
  firstName: string;
  lastName: string;
  email: string;
  telephone: string;
  companyName: string;
  website?: string;
  vatRegistered: string;
  products?: SellWithUsProduct[];
  numberOfProducts?: string;
  question?: string;
  hasOwnRetailOutlet?: string;
  supplierToOtherOutlets?: string;
  images?: string[];
  category: string;
}

export interface SellWithUsArgs {
  input: SellWithUsInput;
}

export interface SellWithUsOutput {
  sellWithUs: boolean;
}

export interface SellWithUsImageInput {
  email: string;
  imageType: string;
  base64Image: string;
}

export interface SellWithUsImageArgs {
  input: SellWithUsImageInput;
}

export interface SellWithUsCategory {
  code: string;
  name: string;
}

export interface QuerySellWithUsCategoriesOutput {
  sellWithUsCategories: SellWithUsCategory[];
}

export enum BundleProductItemTypeEnum {
  Radio = 'RADIO',
  Select = 'SELECT',
  Checkbox = 'CHECKBOX',
  MultiSelect = 'MULTI_SELECT',
}

export enum CustomerOrderStateEnum {
  Pending = 'PENDING',
  PaymentPending = 'PENDING_PAYMENT',
  Processing = 'PROCESSING',
  Packing = 'PACKING',
  Shipped = 'SHIPPED',
  PartShipped = 'PART_SHIPPED',
  Completed = 'DELIVERED',
  Cancelled = 'CANCELLED',
  Refunded = 'REFUNDED',
  SupplierShipped = 'SUPPLIER_SHIPPED',
  CustomerService = 'CUSTOMER_SERVICE',
  Queried = 'QUERIED',
  CustomerDispatched = '*Dispatched',
}

export enum CustomizableOptionTypeEnum {
  Radio = 'RADIO',
  Select = 'SELECT',
  MultiSelect = 'MULTI_SELECT',
  Swatch = 'SWATCH',
  SwatchMultiSelect = 'SWATCH_MULTI_SELECT',
  CheckBox = 'CHECKBOX',
  Text = 'TEXT',
  TextArea = 'TEXT_AREA',
  Hidden = 'HIDDEN',
  Date = 'DATE',
  DateTime = 'DATE_TIME',
  Time = 'TIME',
}

export enum CustomizableOptionDependencyTypeEum {
  And = 'AND',
  Or = 'OR',
}

export enum PriceTypeEnum {
  Dynamic = 'DYNAMIC',
  Fixed = 'FIXED',
  Percent = 'PERCENT',
}

export enum CustomizableSwatchTypeEnum {
  Text = 'TEXT',
  Color = 'COLOR',
  Image = 'IMAGE',
}

export enum GenderEnum {
  Male = 'MALE',
  Female = 'FEMALE',
}

export enum ProductConditionEnum {
  New = 'NEW',
  Refurbished = 'REFURBISHED',
  ExDemoStock = 'EX_DEMO_STOCK',
  DemoStock = 'DEMO_STOCK',
  SlightlyDamagedPackaging = 'SLIGHTLY_DAMAGED_PACKAGING',
  NoPackaging = 'NO_PACKAGING',
  NoTags = 'NO_TAGS',
}

export enum ProductGalleryItemTypeEnum {
  ExternalVideo = 'EXTERNAL_VIDEO',
  Image = 'IMAGE',
  Video = 'VIDEO',
}

export enum ProductTypeEnum {
  Bundle = 'BUNDLE',
  Configurable = 'CONFIGURABLE',
  Downloadable = 'DOWNLOADABLE',
  Grouped = 'GROUPED',
  Simple = 'SIMPLE',
  Virtual = 'VIRTUAL',
}

export enum SavingsFormatEnum {
  Percentage = 'PERCENT',
  Fixed = 'FIXED',
}

export enum SortEnum {
  Asc = 'ASC',
  Desc = 'DESC',
}

export enum StockStatusEnum {
  InStock = 'IN_STOCK',
  OutOfStock = 'OUT_OF_STOCK',
  TimeExpired = 'TIME_EXPIRED',
  TimeRunningOut = 'TIME_RUNNING_OUT',
}

export enum StoreCreditHistoryActionEnum {
  Manual = 'MANUAL',
  Earning = 'EARNING',
  Refunded = 'REFUNDED',
  Used = 'USED',
  Refill = 'REFILL',
  Purchased = 'PURCHASED',
}

export enum CustomerGroupEnum {
  General = 'GENERAL',
  Trusted = 'TRUSTED',
  Retailer = 'RETAILER',
  Wholesale = 'WHOLESALE',
  Vip = 'VIP',
  Staff = 'STAFF',
  Management = 'MGMT',
}

export enum SectionGroupEnum {
  Home = 'HOME',
  Product = 'PRODUCT',
  Checkout = 'CHECKOUT',
  Global = 'GLOBAL',
  MyOrders = 'MYORDERS',
  MyAccount = 'MYACCOUNT',
}

/**
 * DO NOT EXPORT OR USE EVER!!!
 *
 * This is just here to avoid duplication of effort.
 * The two interfaces extending this are each their own types
 * and shouldn't be considered as sharing a parent.
 */
interface AddressInterface {
  id: string;
  label?: string;
  prefix?: string;
  firstName: string;
  middleName?: string;
  lastName: string;
  suffix?: string;
  phone?: string;
  fax?: string;
  taxVat?: string;
  addressLine1: string;
  addressLine2?: string;
  addressLine3?: string;
  organisation?: string;
  aptFloorUnit?: string;
  suburb: Suburb;
  postalCode: string;
  city: string;
  province: Province;
  country: Country;
  pargoPupNumber?: string;
  pudoLocationId?: string;
  isInvalid?: boolean;
}

export interface AddToCartCustomOption {
  id: string;
  value: string;
}

export interface AddCartItemInput {
  type: ProductTypeEnum;
  parentSku?: string;
  sku: string;
  quantity: number;
  customOptions?: AddToCartCustomOption[];
}

export interface CartAddressInterface extends AddressInterface {
  customerAddressId: string | null;
}

/**
 * TODO: This is getting out of hand! Now there are two of them.
 * Clean up address input types. (See AddressInput)
 */
export interface BaseAddressInput {
  label?: string;
  prefix?: string;
  firstName: string;
  middleName?: string;
  lastName: string;
  suffix?: string;
  phone?: string;
  fax?: string;
  taxVat?: string;
  addressLine1: string;
  addressLine2?: string;
  addressLine3?: string;
  organisation?: string;
  aptFloorUnit?: string;
  suburb: SuburbInput;
  postalCode: string;
  city: string;
  province: ProvinceInput;
  country?: CountryInput;
  pargoPupNumber?: string;
  pudoLocationId?: string;
  isDefaultBilling?: boolean;
  isDefaultShipping?: boolean;
  deliveryInstructions?: string;
  latitude?: string;
  longitude?: string;
}

export interface BillingAddress extends CartAddressInterface {
  __typename?: 'BillingAddress';
  notes?: string;
}

export interface BundleProduct extends PhysicalProductInterface {
  __typename?: 'BundleProduct';
  items: BundleProductItem[];
}

export interface BundleProductItem {
  __typename?: 'BundleProductItem';
  id: string;
  sku?: string;
  title: string;
  position?: number;
  isRequired: boolean;
  type: BundleProductItemTypeEnum;
  options: BundleProductOption[];
}

export interface BundleProductOption {
  __typename?: 'BundleProductOption';
  id: string;
  isQuantityChangeable: boolean;
  isDefault: boolean;
  label: string;
  position?: number;
  price: Money;
  quantity: number;
  product: SimpleProduct;
}

export interface Career {
  __typename?: 'Career';
  id: string;
  title: string;
  department?: string;
  content: string;
  location: string;
  sortOrder?: number;
}

export interface Cart {
  __typename?: 'Cart';
  id: string;
  items: CartItemInterface[];
  email?: string;
  isGiftable?: boolean;
  giftMessage?: GiftMessage;
  paymentMethods: CartPaymentMethod[];
  billingAddress?: BillingAddress;
  shippingAddress?: ShippingAddress;
  shippingMethods: CartShippingMethod[];
  voucher: string;
  totals: CartTotals | null;
  quantity: number;
  weight: number;
  isVirtual: boolean;
  hasAlcohol: boolean;
  hasPromotionalDeal?: boolean;
  pargo: CartPargoConfig;
  //TODO: Remove this conditional once in prod for a while
  pudo?: CartPudoConfig;
  errors?: string[];
  upsells?: ProductInterface[];
  messages?: ConfigurableMessage[];
}

export interface CartPargoConfig {
  isAvailable: boolean;
  isFree: boolean;
  message?: string;
}

export interface CartPudoConfig {
  isAvailable: boolean;
  isFree: boolean;
  message?: string;
}

export interface CartAddressInput extends BaseAddressInput {
  deliveryInstructions?: string;
}

export interface CartBillingAddressInput {
  address: CartAddressInput;
  useForShipping?: boolean;
  saveInAddressBook?: boolean;
}

export interface CartItemInterface {
  id: string;
  type: ProductTypeEnum;
  quantity: number;
  quantityLeft: number;
  sku: string;
  name: string;
  brand: string;
  image: File;
  price: Money;
  total: Money;
  isAlcohol: boolean;
  isDisabled: boolean;
  isEarly: boolean;
  isExpired: boolean;
  isSoldOut: boolean;
  error?: string;
  xLeftQuantity?: number;
  activeToDate?: string;
  product: ProductInterface;
  customerDeliveryTime?: CustomerDeliveryTime;
  isGiftVoucher?: boolean;
}

export interface CartPaymentMethod {
  __typename?: 'CartPaymentMethod';
  code: string;
  name: string;
  shortDescription: string;
  description: string;
  isSelected: boolean;
}

export interface CartShippingAddressInput {
  address: CartAddressInput;
  useForBilling?: boolean;
  saveInAddressBook?: boolean;
}

export interface CartShippingMethod {
  __typename?: 'CartShippingMethod';
  carrier: ShippingCarrier;
  method: ShippingMethod;
  baseAmount?: Money;
  amount: Money;
  isAvailable: boolean;
  errorMessage?: string;
  isSelected: boolean;
}

export interface CartShippingMethodInput {
  carrierCode: string;
  methodCode: string;
}

export interface CartTotals {
  __typename?: 'CartTotals';
  tax?: TotalsTax;
  discount?: TotalsDiscount;
  storeCreditUsed?: TotalsStoreCredit;
  grandTotal: Money;
  subtotal: Money;
  shipping: Money | null;
}

export interface ConfigurableCartItem extends CartItemInterface {
  __typename?: 'ConfigurableCartItem';
  options: SelectedConfigurableOption[];
}

export interface ConfigurableProduct extends PhysicalProductInterface {
  __typename?: 'ConfigurableProduct';
  configurableOptions: ConfigurableProductOption[];
  variants: ConfigurableProductVariant[];
}

export interface ConfigurableProductAttributeOption {
  __typename?: 'ConfigurableProductAttributeOption';
  code: string;
  label: string;
  value: string;
}

export interface ConfigurableProductOption {
  __typename?: 'ConfigurableProductOption';
  code: string;
  label: string;
  position?: number;
  values: ConfigurableProductOptionValue[];
}

export interface ConfigurableProductOptionValue {
  __typename?: 'ConfigurableProductOptionValue';
  label: string;
  value: string;
  isSoldOut?: boolean;
}

export interface ConfigurableProductVariant {
  __typename?: 'ConfigurableProductVariant';
  options: ConfigurableProductAttributeOption[];
  product: SimpleProduct;
}

export interface ConfigurableProductVariantProduct {
  __typename?: 'ConfigurableProductVariantProduct';
  id?: string;
  sku?: string;
  price?: Money;
  image?: File;
}

export interface Country {
  __typename?: 'Country';
  id: string;
  code: string;
  name: string;
}

export interface CountryInput {
  id: string;
  code?: string;
  name?: string;
}

export interface CreateCustomerAddressInput {
  label?: string;
  prefix?: string;
  firstName: string;
  middleName?: string;
  lastName: string;
  suffix?: string;
  phone?: string;
  fax?: string;
  taxVat?: string;
  addressLine1: string;
  addressLine2?: string;
  addressLine3?: string;
  organisation?: string;
  aptFloorUnit?: string;
  suburb: SuburbInput;
  postalCode: string;
  city: string;
  province: ProvinceInput;
  country?: CountryInput;
  pargoPupNumber?: string;
  pudoLocationId?: string;
  latitude?: string;
  longitude?: string;
  isDefaultBilling?: boolean;
  isDefaultShipping?: boolean;
}

export interface CreateCustomerInput {
  prefix?: string;
  firstName: string;
  middleName?: string;
  lastName: string;
  suffix?: string;
  gender?: GenderEnum;
  dateOfBirth?: string;
  email: string;
  password: string;
  phone?: string;
  taxVat?: string;
  recaptcha: string;
  subscribe: boolean;
}

export interface ContactUsInput {
  name: string;
  email: string;
  order?: string;
  regarding: string;
  question: string;
  details: string;
  recaptcha: string;
}

export type CustomerAddressInput = BaseAddressInput;

export interface CustomerOrder {
  __typename?: 'CustomerOrder';
  id: string;
  date: string;
  items: CustomerOrderItem[];
  state: CustomerOrderState;
  status: CustomerOrderStatus;
  shippingAddress?: ShippingAddress;
  paymentMethods?: CodeLabelPair[];
  availablePaymentMethods?: CartPaymentMethod[];
  shippingMethods?: CodeLabelPair[];
  totals: CustomerOrderTotals;
  isVirtual: boolean;
  email?: string;
  billingAddress?: BillingAddress;
  quantity: number;
  giftMessage?: GiftMessage;
  canChangeBillingAddress: boolean;
  canChangeShippingAddress: boolean;
  invoiceDownloadLink?: string;
}

export interface CustomerOrderTotalsAndPaymentMethods {
  availablePaymentMethods?: CartPaymentMethod[];
  totals: CustomerOrderTotals;
  items: CustomerOrderItem[];
}

export interface CodeLabelPair {
  code: string;
  label: string;
}

export interface Shipment {
  courier: Courier;
  identifier: string;
  trackingUrl: string;
  quantityShipped: number;
}

export interface CustomerOrderState {
  code: CustomerOrderStateEnum;
  label: string;
}

export interface Courier {
  name: string;
  logo: string;
}

export interface CustomerOrderStatus {
  code?: string;
  label: string;
}

export interface CustomerOrderPaymentMethod {
  code: string;
  label: string;
}

export interface CustomerOrderTotals {
  subtotal: Money;
  grandTotal: Money;
  shipping: Money;
  tax: TotalsTax;
  discount?: TotalsDiscount;
  storeCreditUsed?: TotalsStoreCredit;
  totalPaid?: Money;
  totalDue: Money;
}

interface CustomerOrderItemReturnsConfig {
  isSerialNumberRequired?: boolean;
}

export interface CustomerOrderItem {
  isGiftVoucher?: boolean;
  id: string;
  brand: string;
  sku: string;
  name: string;
  image?: File;
  quantity: CustomerOrderItemQuantity;
  price: Money;
  total: Money;
  customizableOptions?: SelectedCustomizableOptionValueInterface[];
  shipment?: Shipment;
  productId: string;
  productRealId: string;
  customerDeliveryTime?: CustomerDeliveryTime;
  returnsConfig?: CustomerOrderItemReturnsConfig;
}

export interface CustomerOrderItemQuantity {
  ordered: number;
  invoiced: number;
  cancelled: number;
  refunded: number;
  shipped: number;
}

export interface Dimension {
  __typename?: 'Dimension';
  width: DimensionField;
  height: DimensionField;
  length: DimensionField;
  weight: DimensionField;
}

export interface DimensionField {
  __typename?: 'DimensionField';
  value: number;
  formattedValue?: string;
}

export interface File {
  __typename?: 'File';
  id: string;
  url: string;
  path?: string;
  label?: string;
  isCensored?: boolean;
}

export interface GiftMessage {
  __typename?: 'GiftMessage';
  id: string;
  sender: string;
  recipient: GiftMessageRecipient;
  message: string;
  occasion?: string;
}

export interface MutationSubscribeUserOutput {
  subscribeUser: boolean;
}

export interface MutationSubscribeUserArgs {
  email: string;
  firstName: string;
  lastName: string;
}

export interface MutationRemoveStoreCreditFromCartOutput {
  removeStoreCreditFromCart: Cart;
}

export interface MutationSetStoreCreditOnCartOutput {
  setStoreCreditOnCart: Cart;
}

export interface MutationSetVoucherOnCartOutput {
  setVoucherOnCart: Cart;
}

export interface MutationSetPaymentMethodOnCartOutput {
  setPaymentMethodOnCart: Cart;
}

export interface MutationRemoveVoucherFromCartOutput {
  removeVoucherFromCart: Cart;
}

export interface MutationSetNewShippingAddressOnOrderOutput {
  setNewShippingAddressOnOrder: CustomerOrder;
}
export interface MutationSetNewShippingAddressOnOrderArgs {
  orderId: string;
  input: CartShippingAddressInput;
}

export interface MutationSetShippingAddressOnOrderOutput {
  setShippingAddressOnOrder: CustomerOrder;
}
export interface MutationSetShippingAddressOnOrderArgs {
  id: string;
  addressId: string;
  useForBilling?: boolean;
}

export interface GiftMessageInput {
  sender: string;
  recipient: GiftMessageRecipientInput;
  message: string;
  occasion?: string;
}

export interface GiftMessageRecipient {
  __typename?: 'GiftMessageRecipient';
  name: string;
  phone: string;
}

export interface GiftMessageRecipientInput {
  name: string;
  phone: string;
}

export interface GroupedProduct extends PhysicalProductInterface {
  __typename?: 'GroupedProduct';
  items: GroupedProductItem[];
}

export interface GroupedProductItem {
  __typename?: 'GroupedProductItem';
  quantity: number;
  position?: number;
  product: SimpleProduct;
}

export interface Money {
  __typename?: 'Money';
  value: number;
  formattedValue?: string;
}

export interface MutationCreateCustomerArgs {
  input: CreateCustomerInput;
  guestCartId?: string;
}

export interface MutationContactUsArgs {
  input: ContactUsInput;
}

export interface FacebookDataDeleteStatusQueryArgs {
  confirmationCode: string;
}

export interface FacebookDataDeleteStatusQueryOutput {
  facebookDataDeleteStatus: {
    status: string;
  };
}

export interface MutationContactUsOutput {
  contactUs: ContactUs;
}

export interface MutationCreateCustomerOutput {
  createCustomer: Auth;
}

export interface MutationIsEmailAvailableArgs {
  email: string;
}

export interface MutationLoginArgs {
  email: string;
  password: string;
  guestCartId?: string;
}

export interface MutationLoginOutput {
  login: Auth;
}

export interface MutationSocialLoginArgs {
  provider: string;
  accessToken: string;
  guestCartId?: string;
}

export interface MutationSocialLoginOutput {
  socialLogin: Auth;
}

export interface MutationRequestPasswordResetArgs {
  email: string;
  location: string;
}

export interface MutationRequestCustomerPasswordResetOutput {
  requestCustomerPasswordReset: boolean;
}

export interface MutationResetCustomerPasswordArgs {
  id: string;
  resetToken: string;
  newPassword: string;
  autoLogin?: boolean;
  guestCartId?: string;
}

export interface MutationResetCustomerPasswordOutput {
  resetCustomerPassword: Auth;
}

export interface MutationChangeCustomerPasswordArgs {
  currentPassword: string;
  newPassword: string;
}

export interface MutationUpdateCustomerArgs {
  input: UpdateCustomerInput;
}

export interface MutationUpdateCustomerOutput {
  updateCustomer: Customer;
}

export interface MutationCreateCustomerAddressArgs {
  input: CreateCustomerAddressInput;
}

export interface MutationCreateCustomerAddressOutput {
  createCustomerAddress: CustomerAddress;
}

export interface MutationUpdateCustomerAddressArgs {
  addressId: string;
  input: UpdateCustomerAddressInput;
}

export interface MutationUpdateCustomerAddressOutput {
  updateCustomerAddress: CustomerAddress;
}

export interface MutationDeleteCustomerAddressArgs {
  addressId: string;
}

export interface MutationAddCartItemsArgs {
  cartId?: string;
  input: AddCartItemInput[];
  recaptcha: string;
}

export interface MutationCreateEmptyCartArgs {
  cartId?: string;
}

export interface MutationCreateEmptyCartOutput {
  createEmptyCart: string;
}

export interface MutationMergeCartsArgs {
  sourceCartId: string;
  destinationCartId: string;
}

export interface MutationMergeCartsOutput {
  mergeCarts: Cart;
}

export interface MutationAddCartItemsOutput {
  addCartItems: Cart;
}

export interface MutationUpdateCartItemsArgs {
  cartId: string;
  input: UpdateCartItemInput[];
}

export interface MutationUpdateCartItemsOutput {
  updateCartItems: Cart;
}

export interface MutationRemoveCartItemsArgs {
  cartId: string;
  input: string[];
}
export interface MutationRemoveCartItemsOutput {
  removeCartItems: Cart;
}

export interface MutationSetGiftMessageOnCartOutput {
  setGiftMessageOnCart: Cart;
}

export interface MutationSetShippingAddressOnCartOutput {
  setShippingAddressOnCart: Cart;
}

export interface MutationSetShippingMethodOnCartOutput {
  setShippingMethodOnCart: Cart;
}
export interface MutationSetNewShippingAddressOnCartOutput {
  setNewShippingAddressOnCart: Cart;
}

export interface MutationSetNewBillingAddressOnCartArgs {
  cartId: string;
  input: CartBillingAddressInput;
}

export interface MutationSetNewBillingAddressOnCartOutput {
  setNewBillingAddressOnCart: Cart;
}

export interface MutationSetNewShippingAddressOnCartArgs {
  cartId: string;
  input: CartShippingAddressInput;
}

export interface MutationSetBillingAddressOnCartArgs {
  cartId: string;
  addressId: string;
  useForShipping?: boolean;
}

export interface MutationSetShippingAddressOnCartArgs {
  cartId: string;
  addressId: string;
  useForBilling?: boolean;
}

export interface MutationSetShippingMethodOnCartArgs {
  cartId: string;
  input: CartShippingMethodInput;
}

export interface MutationSetVoucherOnCartArgs {
  cartId: string;
  voucher: string;
}

export interface MutationRemoveVoucherFromCartArgs {
  cartId: string;
}

export interface MutationSetStoreCreditOnCartArgs {
  cartId: string;
  amount: number;
}

export interface MutationRemoveStoreCreditFromCartArgs {
  cartId: string;
}

export interface MutationSetGiftMessageOnCartArgs {
  cartId: string;
  input: GiftMessageInput;
}

export interface MutationRemoveGiftMessageFromCartArgs {
  cartId: string;
}
export interface MutationRemoveGiftMessageFromCartOutput {
  removeGiftMessageFromCart: Cart;
}

export interface MutationSetPaymentMethodOnCartArgs {
  cartId: string;
  method: string;
}

export interface MutationSetGuestEmailOnCartArgs {
  cartId: string;
  email: string;
}

export interface OrderDataInput {
  sailthruHid: string;
  sailthruBid: string;
}
export interface MutationPlaceOrderArgs {
  cartId: string;
  orderData?: OrderDataInput;
  subscribe?: boolean;
  recaptcha?: string;
}

export interface MutationPlaceOrderOutput {
  placeOrder: PlaceOrderWithCart;
}

export interface MutationPaymentResponseArgs {
  method: string;
  input: string;
}

export interface MutationPaymentResponseOutput {
  paymentResponse: PaymentProcessResponse;
}

export interface CustomizableProductInterface extends ProductInterface {
  customizableOptions: CustomizableOptionInterface[];
}

export interface CustomizableOptionInterface {
  id: string;
  type: CustomizableOptionTypeEnum;
  label: string;
  isRequired: boolean;
  isDependent: boolean;
  position: number;
}

export interface CustomizableOptionValuesInterface
  extends CustomizableOptionInterface {
  values: CustomizableOptionValueInterface[];
}
export type CustomizableSelectableOption = CustomizableOptionInterface &
  CustomizableOptionValuesInterface;
export type CustomizableSelectOption = CustomizableSelectableOption;
export type CustomizableMultiSelectOption = CustomizableSelectableOption;

/**
 * Intentionally not using CustomizableSelectableOption interface here
 * coz we want the values to be typed as CustomizableSwatchOptionValue (which they are)
 * so we don't have to type guard later when we use them.
 */
export type CustomizableSwatchOption = CustomizableOptionInterface & {
  values: CustomizableSwatchOptionValue[];
};
export type CustomizableSwatchMultiSelectOption = CustomizableOptionInterface & {
  values: CustomizableSwatchOptionValue[];
};

export type CustomizableCheckBoxOption = CustomizableSelectableOption;
export type CustomizableRadioOption = CustomizableSelectableOption;

export interface CustomizableTextOption extends CustomizableOptionInterface {
  maxLength?: number;
}

export interface CustomizableTextAreaOption
  extends CustomizableOptionInterface {
  maxLength?: number;
}

export interface CustomizableOptionValueInterface {
  id: string;
  sku?: string;
  price: CustomizableOptionPrice;
  weight?: DimensionField;
  xLeftQuantity?: number;
  isSoldOut: boolean;
  position: number;
  label: string;
  dependency?: CustomizableOptionDependency;
  image?: File;
  gallery?: CustomizableOptionGalleryImage[];
}

export type CustomizableSelectOptionValue = CustomizableOptionValueInterface;

export interface CustomizableSwatchOptionValue
  extends CustomizableOptionValueInterface {
  swatch: CustomizableSwatchInterface;
}

export interface CustomizableOptionDependency {
  type: CustomizableOptionDependencyTypeEum;
  parents: string[];
}

export interface CustomizableOptionPrice {
  type: PriceTypeEnum;
  percent: number;
  fixed: Money;
}

export interface CustomizableSwatchInterface {
  type: CustomizableSwatchTypeEnum;
  label: string;
}

export type CustomizableTextSwatch = CustomizableSwatchInterface;

export interface CustomizableColorSwatch extends CustomizableSwatchInterface {
  color: string;
}

export interface CustomizableImageSwatch extends CustomizableSwatchInterface {
  image: File;
}

export interface CustomizableOptionGalleryImage {
  id: string;
  roles: string[];
  label?: string;
  file: File;
  position: number;
}

export interface PhysicalProductInterface extends ProductInterface {
  condition: ProductConditionEnum;
  isParallelImport: boolean;
  isFreeShipping: boolean;
  warranty?: Warranty;
  sizeChartImage?: File;
}

export interface PlacedOrderOutput {
  order: CustomerOrder;
  storeCreditRemaining: StoreCredit;
}

export interface ReturnItem {
  sku: string;
  name: string;
  brand: string;
  qty: number;
  originalQty?: number;
  reason?: string;
  orderId?: string;
  images?: {
    packaging?: string[];
    product?: string[];
    barcode?: string[];
    waybill?: string[];
  };
  condition?: {
    complete?: boolean;
    sellable?: boolean;
    labelled?: boolean;
    packaged?: boolean;
  };
  notes?: string;
  image?: string;
  serialNumber?: string;
  tooBigOrTooSmall?: string;
  isSerialNumberRequired?: boolean;
}

export interface MutationSubmitReturnTicketArgs {
  input: OrderReturnTicketInput;
}

export interface MutationSubmitReturnTicketOutput {
  submitReturnTicket: boolean;
}

export interface OrderReturnTicketInput {
  email: string;
  name: string;
  phone: string;
  orderId: string;
  dropOffOrCollect: {
    method: string;
    location: {
      addressLine1: string;
      addressLine2?: string;
      city?: string;
      suburb?: string;
      country?: string;
      province?: string;
      phone?: string;
      postalCode?: string;
    };
  };
  bankTransfer?: {
    name: string;
    number: string;
    bank: string;
  };
  termsAgreed: boolean;
  returns: ReturnItem[];
  preferredRefundMethod?: string;
}

export interface CustomerNewsletterSubscription {
  successful: boolean;
  error: string;
}

export interface PlaceOrderWithCart {
  cart: Cart | null;
  order: CustomerOrder | null;
  storeCreditRemaining: StoreCredit;
  newsletterSubscription: CustomerNewsletterSubscription;
}

export interface ProductGalleryExternalVideo
  extends ProductGalleryItemInterface {
  __typename?: 'ProductGalleryExternalVideo';
  video: ProductGalleryExternalVideoInfo;
}

export interface ProductGalleryImage extends ProductGalleryItemInterface {
  __typename?: 'ProductGalleryImage';
}

export interface ProductGalleryExternalVideoInfo {
  __typename?: 'ProductGalleryExternalVideoInfo';
  title?: string;
  description?: string;
  provider?: string;
  url: string;
  metadata?: string;
}

export interface ProductGalleryItemInterface {
  id: string;
  type: ProductGalleryItemTypeEnum;
  label?: string;
  roles: string[];
  file: File;
  position: number;
}

export interface BannerInterface {
  id: string;
  altText?: string;
  link: string;
  desktopImage: File | null;
  mobileImage: File | null;
  tabletImage: File | null;
  isAssetUrl?: boolean;
}

export interface CmsPageInterface {
  title: string;
  contentHeading: string;
  content: string;
  metaKeywords: string;
  metaDescription: string;
  isActive: boolean;
}

export interface StickyMessageInterface {
  section: string;
  content: string;
}

export interface CustomerDeliveryTime {
  label: string;
  description: string;
}

export interface SizeChart {
  desktopImage: File;
  tabletImage?: File;
  mobileImage?: File;
  recommendation?: string;
  howToMeasure?: {
    desktopImage: File;
    tabletImage: File;
    mobileImage: File;
  };
}

export interface ProductInterface {
  id: string;
  realId: number;
  sku: string;
  name: string;
  shortName: string;
  brand: string;
  description?: string;
  writeup?: ProductWriteUp;
  meta?: ProductMetaAttribute[];
  retailPrice: Money;
  price: Money;
  minPrice: Money;
  hasPriceRange: boolean;
  position?: number;
  activeFromDate?: string;
  activeToDate?: string;
  isReturnable: boolean;
  saving?: ProductSaving;
  shop?: Shop;
  permanentShop?: Shop;
  image: File;
  gallery?: ProductGalleryItemInterface[];
  stockConfig: StockConfig;
  sizeChartImage?: File;
  sizeChart?: SizeChart;
  lockdownText?: string;
  blueTag?: string;
  yellowTag?: string;
  isSoldOut: boolean;
  isPromotionalDeal?: boolean;
  isClearanceSale?: boolean;
  callout?: string;
  xLeftQuantity?: number;
  isLunchtimeProduct: boolean;
  isGiftable?: boolean;
  campaign?: ProductCampaign;
  notes?: ProductNote[];
  isUnderwearOrSwimwear?: boolean;
  isBestSeller?: boolean;
  externalListingLink: string;
  customerDeliveryTime?: CustomerDeliveryTime;
  isGiftVoucher?: boolean;
  payflexInstalment?: Money;
  acm?: string;
  sas?: string;
  topLevelCategories?: Category[];
  // NOTE: no longer used, but still available from the API
  type?: ProductTypeEnum;
  about?: string;
  features?: string[];
  relatedProducts?: ProductInterface[];
  isShippingApplicable?: boolean;
}

export interface ProductCampaign {
  id: string;
  code: string;
  name: string;
  from: string;
  to: string;
}

export interface PromoBlockInterface {
  altText?: string;
  image: File;
  link: string;
  position: number;
}

export interface GalleryImageInterface {
  image: File;
  link?: string;
  position: number;
  altText?: string;
  _galleryType: 'image';
}

export interface SimpleGalleryInterface {
  image: File;
  position?: number;
  id: string;
  shop?: Shop;
  _galleryType: 'simple';
}

export type GalleryItem =
  | ProductInterface
  | GalleryImageInterface
  | SimpleGalleryInterface;

export interface PromoBlockQueryOutput {
  promoBlock: PromoBlockInterface;
}

export interface ProductNote {
  type: string;
  value: string;
}

export interface ProductMetaAttribute {
  attribute: MetaAttribute;
  content: string;
}

export interface MetaAttribute {
  __typename?: 'MetaAttribute';
  name: string;
  value: string;
}

export interface ProductSaving {
  format: SavingsFormatEnum;
  percent: number;
  fixed: Money;
}

export interface ProductSortInput {
  field?: string;
  order?: string;
}

export interface ProductWriteUp {
  body: string;
  color?: string;
}

export interface Province {
  __typename?: 'Province';
  id: string;
  code: string;
  name: string;
}

export interface ProvinceInput {
  id: string;
  code?: string;
  name?: string;
}

/**
 * Categories
 */
export interface Category {
  id: string;
  name: string;
  urlKey?: string;
  position?: number;
  description?: string;
  image?: File;
  bannerImage?: File;
  productCount?: number;
}

/**
 * Config.
 */
export interface CheckoutConfig {
  isGuestAllowed: boolean;
  gracePeriod: number;
}

export interface PargoConfig {
  isEnabled: boolean;
  isFree: boolean;
}

export interface ThemeConfig {
  name: string;
  gradientConfettiPaletteName: string;
  headerBar: {
    color: string;
    confetti: {
      color: string;
    };
    gradient: {
      colors?: string[];
    };
  };
}

export interface ClearanceSaleConfig {
  isActive: boolean;
}

export interface AnotherDayOnlyConfig {
  isActive: boolean;
}

export interface GiftMessageConfig {
  occasions: string[];
}

export interface CurrencyConfig {
  code: string;
  symbol: string;
}

export interface SnapScanConfig {
  snapCode: string;
}

export interface ZapperConfig {
  siteId: string;
  merchantId: string;
}

export interface Configs {
  checkout: CheckoutConfig;
  pargo: PargoConfig;
  themes?: ThemeConfig[];
  clearance: ClearanceSaleConfig;
  anotherDayOnly: AnotherDayOnlyConfig;
  giftMessage: GiftMessageConfig;
  currency: CurrencyConfig;
  snapScan: SnapScanConfig;
  zapper: ZapperConfig;
}

export enum ConfigurableMessagePlatform {
  app = 'app',
  website = 'web',
  vodapayMini = 'vodapay',
}

export enum ConfigurableMessageSection {
  createAccount = 'create_account',
  checkout = 'checkout_page',
  cart = 'cart_slide_out',
  addedToCart = 'added_to_cart_popup',
}

export interface ConfigurableMessage {
  isEnabled: boolean;
  section: ConfigurableMessageSection | string;
  platform: (ConfigurableMessagePlatform | string)[];
  message: string;
}

export interface ConfigMessages {
  messages: ConfigurableMessage[];
}

/**
 * Queries.
 */
export interface QuerySuburbsArgs {
  term: string;
}

export interface QueryCareersArgs {
  pageSize?: number;
  page?: number;
}

export interface QueryCareersOutput {
  careers: Career[];
}

export interface QueryCartArgs {
  cartId: string;
}

export interface QueryCartOutput {
  cart: Cart;
}

export interface QueryCheckoutSyncArgs {
  cartId: string;
}

export interface QueryCheckoutSyncOutput {
  cart: Cart;
  storeCredit: StoreCredit;
}

export interface QueryConfigsOutput {
  configs: Configs;
}

export interface QueryConfigMessagesOutput {
  configs: ConfigMessages;
}

export interface QueryCustomerOutput {
  customer: Customer;
}

export interface QueryCustomerOrdersOutput {
  customerOrders: { totalCount: number; items: [CustomerOrder] };
}

export interface QueryCustomerOrderOutput {
  customerOrder: CustomerOrder;
}

export interface QueryCustomerOrderArgs {
  id: string;
}

export interface QueryCustomerOrderTotalsAndPaymentMethods {
  customerOrder: CustomerOrderTotalsAndPaymentMethods;
}

export interface QueryProductArgs {
  id: string;
  p?: string;
}

export interface QueryConfigsAnotherDayOnlyIsActive {
  configs?: {
    anotherDayOnly?: {
      isActive?: boolean;
    };
  };
}

export interface QueryCategoriesOutput {
  categories: Category[];
}

export type QueryProductOutput = QueryMessagesOutput &
  QueryConfigsAnotherDayOnlyIsActive & {
    product: ProductInterface;
  };

export interface QueryClearanceProductsOutput {
  clearanceProducts: ProductInterface[];
}

export interface QueryCmsPageArgs {
  identifier: string;
}

export interface QueryCmsPageOutput {
  page: CmsPageInterface;
}

export type QueryHomePageOutput = QueryProductsOutput &
  QueryBannersOutput &
  QueryPromoBlocksOutput &
  QueryCampaignProductsOutput &
  QueryMessagesOutput &
  QueryConfigsAnotherDayOnlyIsActive &
  QueryCategoriesOutput;

export type QueryCategoryPageOutput = QueryProductsOutput &
  QueryMessagesOutput &
  QueryConfigsAnotherDayOnlyIsActive &
  QueryCategoriesOutput;

export type QueryPermanentShopPageOutput = QueryPermanentShopProductsOutput &
  QueryBannersOutput &
  QueryPromoBlocksOutput &
  QueryMessagesOutput &
  QueryPermanentShop;

export interface QueryHomePageArgs {
  date: string;
}

export interface QueryPermanentShopPageArgs {
  shopId: string;
  previewShopId?: string;
  date?: string;
}

export interface QueryCategoryPageArgs {
  categoryId: string;
  date: string;
}

export interface QueryProductsOutput {
  products: ProductInterface[];
}

export interface QueryPermanentShopProductsOutput {
  permanentShopProducts: ProductInterface[];
}

export interface QueryBannersOutput {
  banners: BannerInterface[];
}

export type PermanentShopType = {
  id: string;
  name: string;
  color: string;
  blurb: string[];
  shortName: string;
};

export interface QueryPermanentShop {
  permanentShop: PermanentShopType;
}

export interface QueryMessagesOutput {
  messages: StickyMessageInterface[];
}

export interface QueryPromoBlocksOutput {
  promoBlocks: PromoBlockInterface[];
}

export interface QueryCampaignProductsOutput {
  campaignProducts: ProductInterface[];
}

export interface QuerySnapScan {
  snapScan: {
    amountDue: Money;
    link: string;
    image: string;
  };
}

export interface DismissableNotification {
  id: string;
  message: string;
  dismissable: boolean;
  variant: string;
  priority: number;
  timeout?: number;
}

export interface QueryZapper {
  zapper: {
    siteId: number;
    merchantId: number;
    amountDue: Money;
  };
}

export interface RemoveCartItemInput {
  id: string;
}

export interface SelectedConfigurableOption {
  __typename?: 'SelectedConfigurableOption';
  id: string;
  code?: string;
  label: string;
  value: SelectedConfigurableOptionValue;
}

export interface SelectedConfigurableOptionValue {
  __typename?: 'SelectedConfigurableOptionValue';
  id: string;
  label: string;
}

export interface ShippingAddress extends CartAddressInterface {
  __typename?: 'ShippingAddress';
  notes?: string;
  deliveryInstructions?: string;
}

export interface ShippingCarrier {
  __typename?: 'ShippingCarrier';
  code: string;
  title: string;
}

export interface ShippingMethod {
  __typename?: 'ShippingMethod';
  code: string;
  title: string;
}

export interface Shop {
  __typename?: 'Shop';
  id?: string;
  name: string;
  position: number;
  image?: File;
  color?: string;
}

export interface SimpleCartItem extends CartItemInterface {
  __typename?: 'SimpleCartItem';
  customizableOptions?: SelectedCustomizableOptionValueInterface[];
}

export interface SelectedCustomizableOptionValueInterface {
  label: string;
  value: string;
  type: CustomizableOptionTypeEnum;
}

export interface SelectedCustomizableOptionSelectValue
  extends SelectedCustomizableOptionValueInterface {
  label: string;
  value: string;
}

export interface SelectedCustomizableOptionSwatchValue
  extends SelectedCustomizableOptionValueInterface {
  label: string;
  value: string;
  swatch: CustomizableSwatchInterface;
}

export interface SimpleProduct
  extends PhysicalProductInterface,
    CustomizableProductInterface {
  __typename?: 'SimpleProduct';
}

export interface StockConfig {
  __typename?: 'StockConfig';
  isQuantityDecimal: boolean;
  minimumSaleQuantity: number;
  maximumSaleQuantity: number;
  quantityIncrement: StockConfigQuantityIncrement;
  isMaxSaleQtyApplicableToCustomOptions?: boolean;
  isMaxSaleQtyApplicableToCustomerProfile?: boolean;
}

export interface StockConfigQuantityIncrement {
  __typename?: 'StockConfigQuantityIncrement';
  isEnabled: boolean;
  value: number;
}

export interface StockStatus {
  xLeftQuantity?: number;
  sku: string;
  status: StockStatusEnum;
}

export interface StoreCredit {
  __typename?: 'StoreCredit';
  balance?: Money;
}

export interface StoreCreditHistory {
  __typename?: 'StoreCreditHistory';
  id?: string;
  action?: StoreCreditHistoryActionEnum;
  balance: Money;
  balanceDelta: Money;
  isCustomerNotified?: boolean;
  message: string;
  createdAt: string;
}

export interface Suburb {
  __typename?: 'Suburb';
  id: string;
  code: string;
  name: string;
}

export interface SuburbInput {
  id: string;
  code?: string;
  name?: string;
}

export interface SuburbInterface {
  id: string;
  name: string;
  postalCode: string;
  city: string;
  province: Province;
  country?: Country;
}

export interface SuburbArgs {
  variables: QuerySuburbsArgs;
}

export interface SuburbsSearchResult {
  suburbs: SuburbInterface[];
}

export interface SuburbAutoCompleteInterface {
  id: string;
  label: string;
  value: string;
  meta: SuburbInterface;
}

export interface TotalsDiscount {
  __typename?: 'TotalsDiscount';
  total: Money;
  items: TotalsDiscountItem[];
}

export interface TotalsDiscountItem {
  __typename?: 'TotalsDiscountItem';
  label: string;
  code: string;
  amount: Money;
  percent?: number;
}

export interface TotalsStoreCredit {
  __typename?: 'TotalsStoreCredit';
  label?: string;
  amount: Money;
}

export interface TotalsTax {
  __typename?: 'TotalsTax';
  total: Money;
  items: TotalsTaxItem[] | null;
}

export interface TotalsTaxItem {
  __typename?: 'TotalsTaxItem';
  code: string;
  label: string;
  amount: Money;
  percent?: number;
}

export interface UpdateCartItemInput {
  id: string;
  quantity: number;
}

export interface UpdateCustomerAddressInput {
  label?: string;
  prefix?: string;
  firstName: string;
  middleName?: string;
  lastName: string;
  suffix?: string;
  phone?: string;
  fax?: string;
  taxVat?: string;
  addressLine1: string;
  addressLine2?: string;
  addressLine3?: string;
  organisation?: string;
  aptFloorUnit?: string;
  suburb: SuburbInput;
  postalCode: string;
  city: string;
  province: ProvinceInput;
  country?: CountryInput;
  isDefaultBilling?: boolean;
  isDefaultShipping?: boolean;
}

export interface UpdateCustomerInput {
  prefix?: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  suffix?: string;
  gender?: GenderEnum;
  dateOfBirth?: string;
  email?: string;
  password?: string;
  phone?: string;
  taxVat?: string;
}

export interface ContactUs {
  message: string;
}

export interface Auth {
  authToken: string;
  // measured in seconds
  authTokenExpiresIn: number;
  customer: Customer;
  cart?: Cart;
}

export interface Customer {
  __typename?: 'Customer';
  id: string;
  email: string;
  prefix?: string;
  firstName: string;
  middleName?: string;
  lastName: string;
  suffix?: string;
  phone?: string;
  dateOfBirth?: string;
  gender?: GenderEnum;
  taxVat?: string;
  isSubscribed?: boolean;
  addresses?: CustomerAddress[];
  defaultBillingAddressId?: string;
  defaultShippingAddressId?: string;
  storeCredit?: StoreCredit;
  group: CustomerGroup;
  createdAt?: string;
  orderStats?: {
    count: number;
    total: Money;
    average: Money;
  };
  sHid?: string;
}

export interface CustomerAddress extends AddressInterface {
  __typename?: 'CustomerAddress';
  isDefaultBilling?: boolean;
  isDefaultShipping?: boolean;
  deliveryInstructions?: string;
}

export interface CustomerGroup {
  __typename?: 'CustomerGroup';
  id: string;
  code: CustomerGroupEnum;
  name: string;
}

export interface VirtualCartItem extends CartItemInterface {
  __typename?: 'VirtualCartItem';
}

export interface VirtualProduct extends ProductInterface {
  __typename?: 'VirtualProduct';
}

export interface Warranty {
  __typename?: 'Warranty';
  period?: string;
  comment?: string;
}

export interface RequestHeaders {
  token: string;
}

export interface QueryThemeConfigOutput {
  __typename?: 'QueryThemeConfigOutput';
  themeConfig?: ThemeConfiguration;
}

/*TODO: We should be able to remove QueryThemeConfigOutput,
as well as ThemeConfiguration, once we have
decided to permantently remove the `confetti` component.*/
export interface ThemeConfiguration {
  __typename?: 'ThemeConfiguration';
  themes: ThemeConfigTheme[];
}

export interface ThemeConfigTheme {
  __typename?: 'ThemeConfigTheme';
  name: string;
  gradientConfettiPaletteName?: string;
}

export interface PaymentRedirectInfoFields {
  [key: string]: string | number | null;
}

export interface PaymentRedirectFieldMap {
  [key: string]: string;
}

export interface PaymentRedirectInfo {
  url: string;
  method?: string;
  fields?: PaymentRedirectInfoFields;
  fieldMap?: PaymentRedirectFieldMap;
}

export enum PeachCreditCardBrandEnum {
  Visa = 'VISA',
  Master = 'MASTER',
  Amex = 'AMEX',
}

export interface QueryPeachPaymentArgs {
  orderReference: string;
}

export interface QueryPeachCreditCardOutput {
  peachCreditCard: PeachCreditCard;
}

export interface QueryPeachApplePayOutput {
  peachApplePay: PeachApplePay;
}

export interface PeachBasePayment {
  id: string;
  returnUrl: string;
  scriptUrl: string;
}

export interface PeachCreditCard extends PeachBasePayment {
  brands: PeachCreditCardBrandEnum[];
}

export interface PeachApplePay extends PeachBasePayment {
  brands: 'APPLE_PAY';
}

export interface HashMap {
  [key: string]: string | number;
}

export interface PaymentResponse {
  method: string;
  response: HashMap;
}

export interface PaymentProcessResponse {
  operation?: string;
  orderReference: string;
  status: string;
  message?: string;
  isPaymentRequired: boolean;
}

export enum ExternalAddressEnum {
  PARGO = 'PARGO',
  PUDO = 'PUDO',
}

export interface PargoAddressType {
  address1: string;
  address2: string | null;
  addressSms: string;
  businessHours: string;
  city: string;
  distance: null;
  latitude: number;
  longitude: number;
  pargoPointCode: string;
  phoneNumber: string;
  photo?: string | null;
  photo_small?: string | null;
  postalcode: string;
  province: string;
  storeName: string;
  storeOwner: string;
  suburb: string;
}

export interface PargoMessage {
  data?: PargoAddressType;
}

export interface PudoLocation {
  id: string;
  name: string;
  openingHours: string;
  address: string;
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  suburb: string;
  postalCode: string;
  city: string;
  province: string;
  latitude: string;
  longitude: string;
}

export interface PudoMessage {
  data?: {
    selectedLocker: PudoLocation;
  };
}

export interface ExternalAddressFormInput {
  firstName: string;
  lastName: string;
  phone: string;
}

export type ExternalAddress = PudoLocation | PargoAddressType;

export type LayoutEffect = (
  effect: EffectCallback,
  deps?: DependencyList
) => void;
export type MediaQueryObject = { [key: string]: string | number | boolean };

export interface AbTestInterface {
  off: boolean;
  on: boolean;
  ruleId: string;
  source: string;
  value: string;
}

export interface AnalyticsInterface {
  features: Record<string, FeatureDefinition<unknown>>;
  trackingClient: Mixpanel;
}

export interface MutationOneVoucherPayArgs {
  orderReference: string;
  mobile: string;
  pin: string;
}

export interface MutationOneVoucherPayOutput {
  oneVoucherPay: PaymentProcessResponse;
}

export enum ApplePayCapabilities {
  CREDENTIALS_AVAILABLE = 'paymentCredentialsAvailable',
  CREDENTIALS_UNKNOWN = 'paymentCredentialStatusUnknown',
  CREDENTIALS_UNAVAILABLE = 'paymentCredentialsUnavailable',
  UNSUPPORTED = 'applePayUnsupported',
}

export interface ApplePaySessionInterface {
  name: 'ApplePaySession';
  applePayCapabilities?: (
    merchantIdentifier: string
  ) => Promise<{ paymentCredentialStatus: ApplePayCapabilities }>;
  canMakePayments?: () => boolean;
}

export enum PermanentShopEnum {
  EXTRA_TIME_DEALS = 'extra-time-deals',
  EVERYDAY_ESSENTIALS = 'everyday-essentials',
}

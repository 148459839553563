import { ReactElement } from 'react';
import { useState, useEffect } from 'react';
import { SearchResultsOverlay } from 'components/_shared/widgets/search';
import { InstantSearch, Configure as SearchConfig } from 'react-instantsearch';
import { liteClient } from 'algoliasearch/lite';
import config from '../../../../util/load-config';
import SearchErrorFallback from 'components/_shared/widgets/search/error-fallback';
import ErrorBoundary from 'components/_global/error-boundary';

const searchClient = liteClient(config.algolia.appId, config.algolia.apiKey);

const SearchWrapper = ({
  searchIndex,
  isSearchActive,
  close,
  children,
}: {
  searchIndex: string;
  isSearchActive: boolean;
  close: () => void;
  children: ReactElement;
}) => {
  const [isMobileFiltersActive, setIsMobileFiltersActive] = useState(false);

  useEffect(() => {
    if (isSearchActive) {
      document.body.classList.add('noscroll');
    }

    return () => {
      document.body.classList.remove('noscroll');
    };
  }, [isSearchActive]);

  return (
    <InstantSearch indexName={searchIndex} searchClient={searchClient}>
      <SearchConfig clickAnalytics />

      {children}

      {isSearchActive && (
        <ErrorBoundary errorDisplay={<SearchErrorFallback />}>
          <SearchResultsOverlay
            index={searchIndex}
            isMobileFiltersActive={isMobileFiltersActive}
            showMobileFilters={() => setIsMobileFiltersActive(true)}
            hideMobileFilters={() => setIsMobileFiltersActive(false)}
            closeSearch={close}
          />
        </ErrorBoundary>
      )}
    </InstantSearch>
  );
};

export default SearchWrapper;
